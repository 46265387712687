const getOrigin = function () {
  if (
    window.location.origin &&
    window.location.origin.indexOf("localhost") != -1
  ) {
    // return "https://all-wallet.azurewebsites.net";
    return "https://uat-api.rewardsp2p.jp";
  }
  // return "https://all-wallet.azurewebsites.net";
  return "https://uat-api.rewardsp2p.jp";
  // return window.location.origin;
};
// export const API_URL = getOrigin() + "/tsktst/";
export const API_URL = getOrigin() + "/";
export const POST_DEPT_ID = "9611";

export const OS_TYPE = 3;

export const LANGUAGE = {
  EN: "en",
  JA: "ja",
  ZH: "zh",
  KO: "ko",
};

export const MY_CARD_TYPE = {
  ACTIVE: true,
  ARCHIVE: false,
};

export const REDEEMED_CARD_TYPE = {
  ACTIVE: true,
  CASH_OUT_COMPLETED: false,
};

export const PAGINATION_NO_OF_PAGES_TO_BE_DISPLAY = 5;

export const TRANSACTION_STATUSES = {
  Sent: {
    display: "Sent",
    class: "label-Sent",
  },
  Received: {
    display: "Received",
    class: "label-Received",
  },
  Redeemed: {
    display: "Redeemed",
    class: "label-Redeemed",
  },
  Cashout: {
    display: "Cash out",
    class: "label-Cashout",
  },
  Archived: {
    display: "Archived",
    class: "label-Archived",
  },
  undefined: {
    display: "Not Found",
    class: "label-Archived",
  },
  null: {
    display: "Not Found",
    class: "label-Archived",
  },
};

export const getStatusFromDisplay = (display) => {
  for (let i in TRANSACTION_STATUSES) {
    if (TRANSACTION_STATUSES[i].display == display) {
      return i;
    }
  }
};

export const USER_STATUS_FILTERS = [
  "Non Active Users~not-in-use",
  "Users Not LoggedIn~not-loggedin",
  "Users Not Activated~not-actived",
];
export const USER_STATUS_FILTERS_NON_ACTIVE =
  USER_STATUS_FILTERS[0].split("~")[1];
export const USER_STATUS_FILTERS_NOT_LOGGED_IN =
  USER_STATUS_FILTERS[1].split("~")[1];
export const USER_STATUS_FILTERS_NOT_ACTIVATED =
  USER_STATUS_FILTERS[2].split("~")[1];

export const USER_FILTERS = [
  "Last Name~lastName",
  "First Name~firstName",
  "Email Address~emailAddress",
  "Department Name~departmentName",
];

export const TRANSACTION_FILTERS = [
  "Transaction Date~transactionDate",
  "Sender Last Name~senderLastName",
  "Sender First Name~senderFirstName",
  "Receiver Last Name~receiverLastName",
  "Receiver First Name~receiverFirstName",
  "Sender Status~senderstatus",
  "Receiver Status~receiverStatus",
  "Transaction ID~orderID",
  "Cashout Date~Cashout",
];

export const TRANSACTION_FILTERS_DATE = TRANSACTION_FILTERS[0].split("~")[1];
export const TRANSACTION_FILTERS_CASHOUT = TRANSACTION_FILTERS[8].split("~")[1];
export const TRANSACTION_FILTERS_SENDER_STATUS =
  TRANSACTION_FILTERS[5].split("~")[1];
export const TRANSACTION_FILTERS_RECEIVER_STATUS =
  TRANSACTION_FILTERS[6].split("~")[1];

export const CHART_DURATION = [
  //duration_name ~ request_value ~ response_key ~ chart x name ~ department trend response_key
  "Today~0~hour~Hours~transactionDayHour",
  "Current Month~-1~day~Days~transactionMonthDay",
  "Last Month~-2~day~Days~transactionMonthDay",
  "Last 3 Months~-3~month~Months~transactionMonth",
  "Last 6 Months~-6~month~Months~transactionMonth",
  "Current Year~-12~month~Months~transactionMonth",
  "Last Year~-24~month~Months~transactionMonth",
];
export const DEFAULT_CHART_DURATION = CHART_DURATION[1];

export const DASHBOARD_USERS_COUNTS_CHART_DURATION = [
  CHART_DURATION[0],
  CHART_DURATION[1],
];
export const DEFAULT_DASHBOARD_USERS_COUNTS_CHART_DURATION =
  DASHBOARD_USERS_COUNTS_CHART_DURATION[0];

export const getDateRangeFromDuration = (duration) => {
  const nowDt = new Date();
  let dateRangeObj = {
    fromDate: new Date(),
    toDate: new Date(),
  };
  if (duration == 0) {
    //today
    return dateRangeObj;
  }
  if (duration == -1) {
    //Current month
    dateRangeObj.fromDate = new Date(nowDt.getFullYear(), nowDt.getMonth(), 1);
    dateRangeObj.toDate = new Date(
      nowDt.getFullYear(),
      nowDt.getMonth() + 1,
      0
    );
  }
  if (duration == -2) {
    //Last month
    dateRangeObj.fromDate = new Date(
      nowDt.getFullYear(),
      nowDt.getMonth() - 1,
      1
    );
    dateRangeObj.toDate = new Date(nowDt.getFullYear(), nowDt.getMonth(), 0);
  }
  if (duration == -3) {
    //Last 3 months
    dateRangeObj.fromDate = new Date(
      nowDt.getFullYear(),
      nowDt.getMonth() - 3,
      1
    );
    dateRangeObj.toDate = new Date(nowDt.getFullYear(), nowDt.getMonth(), 0);
  }
  if (duration == -6) {
    //Last 6 months
    dateRangeObj.fromDate = new Date(
      nowDt.getFullYear(),
      nowDt.getMonth() - 6,
      1
    );
    dateRangeObj.toDate = new Date(nowDt.getFullYear(), nowDt.getMonth(), 0);
  }
  if (duration == -12) {
    //Current year
    dateRangeObj.fromDate = new Date(nowDt.getFullYear(), 0, 1);
    dateRangeObj.toDate = new Date(nowDt.getFullYear(), 11, 31);
  }
  if (duration == -24) {
    //Last year
    dateRangeObj.fromDate = new Date(nowDt.getFullYear() - 1, 0, 1);
    dateRangeObj.toDate = new Date(nowDt.getFullYear() - 1, 11, 31);
  }
  // dateRangeObj.fromDate = dateRangeObj.fromDate.toString();
  // dateRangeObj.toDate = dateRangeObj.toDate.toString();
  return dateRangeObj;
};

export const CHART_DATA_TYPE = [
  "POINTS~text.label.Points",
  "TRANSACTION~text.label.Transactions",
];

export const CHART_DATA_FOR = [
  "SENT~text.label.Sent",
  "RECEIVED~text.label.Received",
];

export const ORDER_TYPE = {
  EAT_IN: "EATIN",
  TAKE_AWAY: "TAKEAWAY",
};

export const MAX_AMOUNT_FOR_CASH_PAYMENT = 49999;

export const LS_AUTH_DETAILS_KEY = "dhflkahskhedfhklhs";
export const LS_USER_DETAILS_KEY = "dhflkahrrrskhdfdsfdsedfhklhs";

export class MY_CARD_TAB_INFO {
  static SENT = "SENT";
  static RECEIVED = "RECEIVED";
  static REDEEMED = "REDEEMED";
}

export const APPINSIGHTS_KEY = () => {
  return "71774603-b6ad-47c8-9143-d7a497303b6d";
};

